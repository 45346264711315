import axios from "axios";

export default {
  import(formData) {
    return axios.post("/api/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
};
