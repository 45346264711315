<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="users"
      :items-length="totalItems"
      :search="search"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog('ADMIN')"
            dark
          >
            {{ $t("admin.new-admin") }}
          </v-btn>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog()"
            dark
          >
            {{ $t("admin.new-commercial") }}
          </v-btn>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog('CUSTOMER')"
            dark
          >
            {{ $t("admin.new-customer-user") }}
          </v-btn>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.fK_UserRole="{ item }">
        <v-chip color="#ffb300" dark>
          {{ rolesName(item) }}
        </v-chip>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'clients'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-user
      :modelDialog="userDialog"
      @close="close()"
      :specificRoles="specificRoles"
    ></add-edit-user>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify, mdiAccountPlus } from "@mdi/js";
import AddEditUser from "@/components/admin/AddEditUser.vue";
import paginate from "@/mixins/paginations";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import { mapState } from "vuex";

export default {
  name: "UserAdmin",
  mixins: [paginate],
  components: {
    PaginationDynamic,
    AddEditUser
  },
  data() {
    return {
      mdiAccountPlus,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      dialog: false,
      dialogDelete: false,
      userDialog: false,
      specificRoles: "",
      headers: [
        {
          title: "Email",
          align: "start",
          sortable: false,
          key: "email"
        },
        { title: "Nom", key: "lastName" },
        { title: "Prenom", key: "firstName" },
        { title: "Role", key: "fK_UserRole" },
        { title: "Actions", key: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.users.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount,
      editedId: (state) => state.users.editedId,
      roles: (state) => state.roles.items
    }),
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    close() {
      this.specificRoles = "";
      this.userDialog = false;
    },
    rolesName(item) {
      return this.roles.find((role) => role.id === item.fK_UserRole)?.name;
    },
    openDialog(roles) {
      this.$store.commit("users/setEditedId", -1);
      this.$store.commit("users/setEditedItem", Object.assign({}, {}));
      if (roles) this.specificRoles = roles;
      this.userDialog = true;
    },
    editItem(item) {
      this.$store.commit("users/setEditedId", item.id);
      this.$store.commit("users/setEditedItem", Object.assign({}, item));
      this.userDialog = true;
    },
    toSort(item) {
      this.sortBy(item, "users");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "users");
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.user"))) {
        this.$store
          .dispatch("users/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.user-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "users");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.user-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
