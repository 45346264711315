import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/statsCustomer${paginationString.text}`);
    } else {
      return axios.get("/api/statsCustomer");
    }
  },
  getByCustomerId(id) {
    return axios.get(`/api/statsCustomer/customer/${id}`);
  },
  recalculatePoints(id) {
    return axios.post(`/api/statsCustomer/recalculate/${id}`);
  },
  updatePoints(data) {
    return axios.post("api/statsCustomer/updatePoints", data);
  }
};
