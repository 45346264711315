<template>
  <v-container class="login-container" fluid>
    <div class="background-overlay"></div>

    <v-card
      width="500"
      class="login-card"
      color="#FFFFFF"
      style="border-radius: 0px"
    >
      <v-card-title :style="fontStyles.fontFamilyFutura">
        {{ $t("logged-in") }}
      </v-card-title>
      <v-form
        ref="form"
        @submit.prevent="submit"
        @keydown.enter.prevent="submit"
      >
        <v-card-text class="pb-0">
          <v-text-field
            v-model="email"
            rounded="0"
            :rules="emailRules"
            :counter="40"
            required
            hide-details
            variant="outlined"
            class="login-btns"
            :label="this.$t('login')"
            :style="fontStyles.fontFamilyEgyptienne"
          >
            <template v-slot:prepend-inner>
              <v-icon
                :icon="mdiAccountOutline"
                size="large"
                class="mr-3"
              ></v-icon>
              <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            </template>
          </v-text-field>

          <v-text-field
            v-model="password"
            :rules="passwordRules"
            rounded="0"
            :append-inner-icon="showPwd ? mdiEye : mdiEyeOff"
            :type="showPwd ? 'text' : 'password'"
            @click:append-inner="showPwd = !showPwd"
            :counter="60"
            required
            hide-details
            variant="outlined"
            class="login-btns"
            :label="this.$t('password')"
            :style="fontStyles.fontFamilyEgyptienne"
          >
            <template v-slot:prepend-inner>
              <v-icon :icon="mdiKeyOutline" size="large" class="mr-3"></v-icon>
              <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-actions class="card-actions-component">
          <div class="card-action-container">
            <div class="login-button-action-container">
              <v-spacer></v-spacer>
              <v-btn
                rounded="0"
                class="me-4 mb-2 text-white"
                color="#395d5b"
                @click="submit"
                @keydown.enter.prevent="submit"
                :loading="loading"
                variant="elevated"
                :prepend-icon="mdiLogin"
                :style="fontStyles.fontFamilyFuturaButton"
              >
                {{ this.$t("logged-in") }}
              </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="microsoft-btn-container">
              <v-btn
                rounded="0"
                class="mb-2 microsoft-auth-btn"
                @click="microsoftAuth"
                :loading="loading"
                elevation="4"
                variant="elevated"
                :style="fontStyles.fontFamilyFuturaButton"
              >
                <template v-slot:prepend>
                  <img
                    :src="microsoftSVG"
                    class="microsoft-svg"
                    alt="microsoft"
                  />
                  {{ $t("auth-microsoft") }}
                </template>
              </v-btn>
            </div>
            <div class="text-right">
              <v-btn
                variant="text"
                class="mb-2 text-none"
                @click="resetPasswordDialog = true"
                :style="fontStyles.fontFamilyHumanist"
              >
                {{ $t("forgot-password") }}
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
    <reset-password-dialog
      v-model="resetPasswordDialog"
      @close="resetPasswordDialog = false"
    ></reset-password-dialog>
  </v-container>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLogin,
  mdiKeyOutline,
  mdiEye,
  mdiEyeOff
} from "@mdi/js";
import microsoftSVG from "@/assets/svg/microsoft.svg";
import axios from "axios";
import fontMixin from "@/mixins/fontMixin";
import ResetPasswordDialog from "./ResetPasswordDialog.vue";

export default {
  name: "LoginComponent",
  mixins: [fontMixin],
  components: {
    ResetPasswordDialog
  },
  data() {
    return {
      mdiAccountOutline,
      microsoftSVG,
      mdiLogin,
      mdiEye,
      mdiEyeOff,
      mdiKeyOutline,
      showPwd: false,
      valid: false,
      loading: false,
      email: "",
      password: "",
      resetPasswordDialog: false,
      passwordRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        },
        (value) => {
          if (value.length >= 8) return true;
          return this.$t("rules.password-8");
        }
      ],
      emailRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;
          return this.$t("rules.email-valid");
        }
      ]
    };
  },
  inject: ["$msalInstance"],
  mounted() {
    this.$msalInstance.initialize();
  },
  methods: {
    async microsoftAuth() {
      this.loading = true;
      try {
        await this.$msalInstance.loginPopup({
          scopes: ["user.read"],
          redirectUri: process.env.VUE_APP_CALLBACK_URL
        });
        const myAccount = this.$msalInstance.getAllAccounts();
        this.$msalInstance.account = myAccount[0];

        const response = await this.$msalInstance.acquireTokenSilent({
          account: this.$msalInstance.account
        });

        const token = response.account.idToken;
        axios.defaults.headers.Authorization = `Bearer ${token}`;

        // Stocke le token et l'utilisateur dans le store
        this.$store.commit("auth/setToken", token);
        this.$store.commit("auth/setUser", response.account);

        this.$store
          .dispatch("users/getUserInfosWithCustomers")
          .then((response) => {
            this.loading = false;
            if (response.user.userRole === "ADMIN") {
              this.$router.push({ name: "admin" });
            } else {
              if (response.customers.length > 1) {
                this.$router.push({ name: "select-customer" });
              } else if (response.customers.length === 1) {
                this.$store.commit(
                  "users/setSelectedCustomer",
                  response.customers[0]
                );
                const data = {
                  customerId: response.customers[0].id,
                  name: ""
                };
                this.$store
                  .dispatch("fetchForCustomerUser", data)
                  .then(() => {
                    this.$router.push({ name: "home" });
                  })
                  .catch(() => {
                    this.$store.dispatch("showSnackbar", {
                      message: this.$t("errors.missing-information"),
                      color: "error"
                    });
                    this.$store.commit("auth/clearAuthData");
                    this.$store.dispatch("users/resetSelectedCustomer");
                    this.$router.push({ name: "login" });
                  });
              } else {
                this.$store.dispatch("showSnackbar", {
                  message: this.$t("errors.missing-information"),
                  color: "error"
                });
                this.$store.commit("auth/clearAuthData");
                this.$store.dispatch("users/resetSelectedCustomer");
                this.$router.push({ name: "login" });
              }
            }
          })
          .catch(() => {
            this.$store.commit("auth/clearAuthData");
            this.$store.dispatch("users/resetSelectedCustomer");
            this.$store.dispatch("showSnackbar", {
              message: this.$t("errors.unauthorized-user"),
              color: "error"
            });
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("showSnackbar", {
          message: this.$t("errros.auth-failed") + " " + error.message,
          color: "error"
        });
      }
    },
    async submit() {
      this.loading = true;
      const { valid } = await this.$refs.form.validate();
      try {
        if (valid) {
          const data = {
            Email: this.email,
            Password: this.password
          };
          this.$store
            .dispatch("auth/login", data)
            .then((response) => {
              const token = response.token;
              axios.defaults.headers.Authorization = `Bearer ${token}`;
              this.$store
                .dispatch("users/getUserInfosWithCustomers")
                .then((response) => {
                  this.loading = false;
                  if (response.user.userRole === "ADMIN") {
                    this.$router.push({ name: "admin" });
                  } else {
                    if (response.customers.length > 1) {
                      this.$router.push({ name: "select-customer" });
                    } else if (response.customers.length === 1) {
                      this.$store.commit(
                        "users/setSelectedCustomer",
                        response.customers[0]
                      );
                      const data = {
                        customerId: response.customers[0].id,
                        name: ""
                      };
                      this.$store
                        .dispatch("fetchForCustomerUser", data)
                        .then(() => {
                          this.$router.push({ name: "home" });
                        })
                        .catch(() => {
                          this.$store.dispatch("showSnackbar", {
                            message: this.$t("errors.missing-information"),
                            color: "error"
                          });
                          this.$store.commit("auth/clearAuthData");
                          this.$store.dispatch("users/resetSelectedCustomer");
                          this.$router.push({ name: "login" });
                        });
                    } else {
                      this.$store.dispatch("showSnackbar", {
                        message: this.$t("errors.missing-information"),
                        color: "error"
                      });
                      this.$store.commit("auth/clearAuthData");
                      this.$store.dispatch("users/resetSelectedCustomer");
                      this.$router.push({ name: "login" });
                    }
                  }
                })
                .catch(() => {
                  this.$store.commit("auth/clearAuthData");
                  this.$store.dispatch("users/resetSelectedCustomer");
                  this.$store.dispatch("showSnackbar", {
                    message: this.$t("errors.unauthorized-user"),
                    color: "error"
                  });
                  this.loading = false;
                });
            })
            .catch(() => {
              this.loading = false;
              this.$store.dispatch("showSnackbar", {
                message: this.$t("errors.unauthorized-user"),
                color: "error"
              });
            });
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("showSnackbar", {
          message: this.$t("errros.auth-failed") + " " + error.message,
          color: "error"
        });
      }
    }
  }
};
</script>

<style>
.microsoft-auth-btn {
  margin-top: 10px;
}
.card-actions-component {
  display: contents;
}

.login-button-action-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.microsoft-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.microsoft-svg {
  width: 25px;
  height: 25px;
}

.login-btns {
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
  margin-bottom: 10px;
}

.login-container {
  height: 100%;
  display: flex; /* Active Flexbox */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  position: relative; /* Nécessaire pour le positionnement absolu de l'overlay */
}

.background-overlay {
  background-image: url("../assets/login_background.jpg");
  background-size: cover;
  background-position: center; /* Centre l'image */
  filter: blur(10px) grayscale(100%);
  -webkit-filter: blur(10px) grayscale(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Couleur blanche */
  opacity: 0.5; /* Ajuste l'opacité ici */
  z-index: 1; /* Assure que l'overlay est au-dessus de l'image mais en dessous du contenu */
  pointer-events: none; /* Permet aux clics de passer à travers ce calque */
}

.color-button {
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}

.login-card {
  z-index: 2;
  margin-top: -200px; /* Default for large screens */
}

@media (min-width: 1200px) {
  .login-card {
    margin-top: -200px; /* Large screens */
  }
}

@media (max-height: 810px) {
  .login-card {
    margin-top: 0px; /* Medium screens */
  }

  /* .v-footer {
    max-height: 150px;
  } */
}

@media (min-width: 768px) and (max-width: 1199px) {
  .login-card {
    margin-top: 0px; /* Medium screens */
  }

  /* .v-footer {
    max-height: 150px;
  } */
}

@media (max-width: 767px) {
  .login-card {
    margin-top: -50px; /* Small screens */
  }
}

@media (max-width: 480px) {
  .login-card {
    margin-top: 0; /* Extra small screens */
  }
}
</style>
