<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <h1 :style="fontStyles.fontFamilyFutura" class="text-color">
        {{ this.$t("services-and-benefits") }}
      </h1>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-table class="elevation-1">
          <thead>
            <tr class="header-background">
              <th class="text-left"></th>
              <th class="text-left" :style="fontStyles.fontFamilyEgyptienne">
                STATUT 1
              </th>
              <th class="text-left" :style="fontStyles.fontFamilyEgyptienne">
                STATUT 2
              </th>
              <th class="text-left" :style="fontStyles.fontFamilyEgyptienne">
                STATUT 3
              </th>
              <th class="text-left" :style="fontStyles.fontFamilyEgyptienne">
                STATUT 4
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in services"
              :key="item.title"
              :style="fontStyles.fontFamilyEgyptienne"
            >
              <td class="pb-3">
                <div>
                  {{ item.title }}
                </div>
                <div>
                  {{ item.subtitle }}
                </div>
              </td>
              <td class="pb-3 pt-3">
                <v-icon
                  :color="item.statut1 ? '#ffb500' : '#b2b4ae'"
                  :icon="item.statut1 ? mdiCheckBold : mdiCloseThick"
                  size="40"
                ></v-icon>
              </td>
              <td class="pb-3 pt-3">
                <v-icon
                  :color="item.statut2 ? '#ffb500' : '#b2b4ae'"
                  :icon="item.statut2 ? mdiCheckBold : mdiCloseThick"
                  size="40"
                ></v-icon>
              </td>
              <td class="pb-3 pt-3">
                <v-icon
                  :color="item.statut3 ? '#ffb500' : '#b2b4ae'"
                  :icon="item.statut3 ? mdiCheckBold : mdiCloseThick"
                  size="40"
                ></v-icon>
              </td>
              <td class="pb-3 pt-3">
                <v-icon
                  :color="item.statut4 ? '#ffb500' : '#b2b4ae'"
                  :icon="item.statut4 ? mdiCheckBold : mdiCloseThick"
                  size="40"
                ></v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { mdiCheckBold, mdiCloseThick } from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";

export default defineComponent({
  name: "HomeView",
  mixins: [fontMixin],
  components: {},
  data() {
    return {
      mdiCheckBold,
      mdiCloseThick,
      services: [
        {
          title: this.$t("services-benefits.first.title"),
          subtitle: this.$t("services-benefits.first.description"),
          statut1: true,
          statut2: true,
          statut3: true,
          statut4: true
        },
        {
          title: this.$t("services-benefits.second.title"),
          subtitle: this.$t("services-benefits.second.description"),
          statut1: true,
          statut2: true,
          statut3: true,
          statut4: true
        },
        {
          title: this.$t("services-benefits.third.title"),
          subtitle: this.$t("services-benefits.third.description"),
          statut1: true,
          statut2: true,
          statut3: true,
          statut4: true
        },
        {
          title: this.$t("services-benefits.fourth.title"),
          subtitle: this.$t("services-benefits.fourth.description"),
          statut1: false,
          statut2: false,
          statut3: true,
          statut4: true
        }
      ]
    };
  },
  methods: {}
});
</script>

<style>
.header-background {
  background-color: #b2b4ae;
}

.text-color {
  color: #ffb500;
}
</style>
