<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-card-title :style="fontStyles.fontFamilyFutura" class="pb-5">
        <div class="pb-2">Conditions Générales d'Utilisation</div>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          L'accès et l'utilisation de ce Site Internet sont soumis aux
          conditions suivantes :
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Toute utilisation de ce Site Internet vaut acceptation des présentes
            conditions.
          </div>
          <div class="pb-3">
            Ce Site Internet a été développé et est administré par Stryker
            France. Stryker France se réserve le droit de suspendre ou de
            modifier tout ou partie de ce Site ainsi que ses Conditions
            Générales d'Utilisation et sa politique de confidentialité. Ces
            modifications peuvent être apportées à l’entière discrétion de
            Stryker France et sans notification préalable. Stryker France invite
            par conséquent, lors de votre prochaine consultation du Site, à
            relire les présentes conditions et à prendre note des éventuelles
            modifications qui ont pu y être apportées.
          </div>
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Droits d’auteur
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Toutes les informations, documents et illustrations publiés sur ce
          Site sont la propriété exclusive de Stryker France, de l’une des
          filiales du groupe Stryker ou de tiers ayant accordé une licence
          quelle qu’elle soit à Stryker France sur leurs informations. Toute
          autorisation d'utilisation de ces informations est accordée sous
          réserve que la mention sur les droits d'auteur soit intégralement
          reproduite sur tous les exemplaires, que ces informations soient
          utilisées exclusivement à des fins personnelles et non commerciales,
          qu'elles ne soient modifiées en aucune manière et que toutes les
          illustrations récupérées sur le Site ne soient utilisées qu’avec le
          texte qui les accompagne.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Marques de commerce et autres droits de propriété intellectuelle
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Toutes les marques commerciales figurant sur ce Site sont la propriété
          de Stryker France ou du groupe Stryker, sauf indication contraire ou
          sauf si elles peuvent être clairement identifiées comme étant la
          propriété d’un tiers. Toute utilisation non autorisée de ces marques
          de commerce ou d'autres éléments est expressément interdite et
          constitue une violation des lois sur les droits d'auteur, les marques
          commerciales ou autres droits de propriété intellectuelle.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Responsabilité limitée
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Stryker France a rédigé les informations fournies sur ce Site Internet
          en se référant à des sources tant internes qu'externes et ce, avec
          toute la diligence voulue et en se fondant sur l'état actuel de ses
          connaissances. Stryker France s’efforce d'étoffer et d'actualiser ces
          informations aussi régulièrement que possible. L’information sur ce
          Site Internet est destinée à vous présenter le Programme One Stryker,
          son fonctionnement et vos droits au sein dudit Programme. Aucune
          garantie, tant tacite qu'explicite, ne peut être toutefois donnée
          quant à l'exhaustivité et à l'exactitude des informations reprises sur
          ce Site Internet. Veuillez noter que cette information, bien que
          précise le jour de sa publication, pourrait ne plus être à jour au
          moment où vous la consultez. Stryker France vous recommande par
          conséquent de vérifier toutes les informations qui figurent sur ce
          Site Internet avant de les utiliser sous quelque forme que ce soit.
          Les conseils donnés sur ce Site Internet ne vous dispensent nullement
          de mener vos propres vérifications quant aux derniers conseils qui
          vous ont été donnés, en vue de leur adéquation aux processus et
          objectifs prévus. Si vous avez besoin de conseils ou d’instructions
          concernant le Programme One Stryker, veuillez nous contacter
          directement. Les utilisateurs de ce Site Internet déclarent accepter
          le fait qu'ils accèdent à ce Site et aux éléments qu'il contient à
          leurs propres risques et périls. Ni Stryker France ni les tiers
          impliqués dans la conception, la réalisation ou le transfert de ce
          Site Internet ne peuvent être tenus responsables des dommages
          matériels ou corporels résultant de l'accès à ce Site ou de
          l'impossibilité d'y accéder, ou de l'utilisation de ce Site ou de
          l'impossibilité de l'utiliser, ou résultant d'informations données sur
          ce Site Internet et auxquelles vous vous êtes référés.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Protection des données
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Stryker France s’engage à protéger les données personnelles et
          confidentielles des utilisateurs du Site Internet conformément à sa
          <a href="https://www.onesyk.fr/privacy" target="_blank"
            >Déclaration de Confidentialité</a
          >.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Liens ou références à d’autres Sites Internet
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Ce Site peut comporter des liens ou des références à des sites
          internet tiers. L’insertion de ces liens ne signifie aucunement que
          Stryker France en approuve le contenu, qu’elle accepte une quelconque
          responsabilité quant à la disponibilité ou au contenu de ces sites ou
          qu’elle peut être tenue responsable des dommages ou préjudices
          résultant de l’utilisation de ces contenus, quelle qu’en soit la
          forme. Le choix de liens ne saurait en aucun cas restreindre les
          utilisateurs aux pages vers lesquelles ces liens renvoient le cas
          échéant. Stryker France conserve le droit de retirer à tout moment un
          lien de son Site Internet.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Informations communiquées par vous-même
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          L’utilisateur de ce Site est entièrement responsable du contenu et de
          l’exactitude des informations qu’il envoie à Stryker France ainsi que
          du respect des éventuels droits des tiers liés à ces informations.
          L’utilisateur donne son consentement à Stryker France pour stocker ces
          informations et utiliser le contenu de ces messages, quelle qu’en soit
          la finalité.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Utilisateurs internationaux
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Ce Site est exclusivement conçu pour être utilisé en France. Stryker
          France ne garantit aucunement que les détails qu’il contient soient
          également valables en dehors de la France. Si vous visitez ce Site ou
          téléchargez son contenu depuis un lieu autre que la France, sachez
          qu’il vous incombe de vérifier que vous agissez dans le respect de la
          législation applicable dans ce lieu. Il se peut que le Programme cité
          et présenté sur ce Site ne soit pas proposé dans un autre pays.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Déclarations prospectives
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Ce Site Internet est susceptible de contenir des déclarations
          prospectives se fondant sur les attentes et les hypothèses actuelles
          de Stryker France. Divers risques, incertitudes et autres facteurs
          connus et inconnus sont susceptibles d’entraîner des écarts notables
          entre les résultats, la situation financière, l’évolution et les
          performances réels de l’entreprise et ceux évoqués ici. Ces facteurs
          incluent ceux présentés dans les rapports publics de Stryker qui sont
          disponibles sur le Site Internet de Stryker
          <a href="https://www.stryker.com" target="_blank"
            >https://www.stryker.com</a
          >. L’entreprise ne saurait être tenue pour responsable de l'éventuelle
          mise à jour ou révision de son contenu dans le but de s’adapter à de
          futurs évènements ou développements.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Droit applicable
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div>
            Les présentes Conditions Générales d'Utilisation sont régies par le
            droit français.
          </div>
          <div>Date d’établissement : février 2025</div>
          <div>Copyright © Stryker France</div>
          <div>Dernière mise à jour : Vendredi 1er février 2025</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import fontMixin from "@/mixins/fontMixin";
export default {
  name: "TermsOfUseView",
  mixins: [fontMixin]
};
</script>

<style>
.title-color-privacy {
  color: #ffb500;
}
</style>
