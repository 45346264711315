import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/gimCustoms${paginationString.text}`);
    } else {
      return axios.get("/api/gimCustoms");
    }
  },
  store(gimCustom) {
    return axios.post("/api/gimCustoms", gimCustom);
  },
  delete(id) {
    return axios.delete(`/api/gimCustoms/${id}`);
  },
  update(id, gimCustom) {
    return axios.put(`/api/gimCustoms/${id}`, gimCustom);
  }
};
