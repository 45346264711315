import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(
        `/api/address/withcustomerdetails${paginationString.text}`
      );
    } else {
      return axios.get("/api/address/withcustomerdetails");
    }
  },
  getAddressByCustomerId(customerId) {
    return axios.get(`/api/address/customer/${customerId}`);
  },
  store(address) {
    return axios.post("/api/address", address);
  },
  delete(id) {
    return axios.delete(`/api/address/${id}`);
  },
  update(id, address) {
    return axios.put(`/api/address/${id}`, address);
  }
};
