<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="ordersOneStryker"
      :items-length="totalItems"
      :search="search"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>

          <import-file-dialog :table="'ordersOneStryker'"></import-file-dialog>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'ordersOneStryker'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-order-one-stryker
      :modelDialog="orderOneStrykerDialog"
      @close="orderOneStrykerDialog = false"
    ></add-edit-order-one-stryker>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify, mdiAccountPlus } from "@mdi/js";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import ImportFileDialog from "./ImportFileDialog.vue";
import AddEditOrderOneStryker from "./admin/AddEditOrderOneStryker.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "OrderOneStrykerAdmin",
  components: {
    ImportFileDialog,
    PaginationDynamic,
    AddEditOrderOneStryker
  },
  mixins: [paginate],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiAccountPlus,
      dialog: false,
      dialogDelete: false,
      orderOneStrykerDialog: false,
      headers: [
        {
          title: this.$t("admin.customer-number"),
          align: "start",
          sortable: false,
          key: "customer.number"
        },
        {
          title: this.$t("admin.customer-name"),
          key: "customer.name"
        },
        { title: this.$t("order-number"), key: "orderNumber" },
        { title: this.$t("total"), key: "totalAmount" },
        { title: this.$t("actions"), key: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      ordersOneStryker: (state) => state.ordersOneStryker.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount
    }),
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },

  watch: {},

  methods: {
    toSort(item) {
      this.sortBy(item, "ordersOneStryker");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "ordersOneStryker");
    },
    editItem(item) {
      this.$store.commit("ordersOneStryker/setEditedId", item.id);
      this.$store.commit(
        "ordersOneStryker/setEditedItem",
        Object.assign({}, item)
      );
      this.orderOneStrykerDialog = true;
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
