import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/orderOneStryker${paginationString.text}`);
    } else {
      return axios.get("/api/orderOneStryker");
    }
  },
  getCommands(id) {
    return axios.get(`/api/orderOneStryker/customer/${id}`);
  },
  createOrder(data) {
    return axios.post(`/api/orderOneStryker/createOrderFromCart`, data);
  },
  downloadInvoice(id) {
    return axios.get("/api/orderOneStryker/download/" + id, {
      responseType: "blob"
    });
  },
  getDetails(id) {
    return axios.get(`/api/orderOneStryker/${id}/details`);
  }
};
