import axios from "axios";

export default {
  get() {
    return axios.get("/api/TermsAndConditions");
  },
  store(data) {
    return axios.post("/api/TermsAndConditions", data);
  },
  update(data) {
    return axios.put("/api/TermsAndConditions", data);
  },
  acceptedOrNot(user) {
    return axios.get(`/api/TermsAndConditions/accepted/${user}`);
  },
  getCurrent() {
    return axios.get("/api/TermsAndConditions/current");
  },
  acceptConditions(data) {
    return axios.post("/api/TermsAndConditions/accept", data);
  }
};
