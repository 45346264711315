import strykerCommands from "../../api/strykerCommands";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    editedItem: {
      customerReported: {},
      customerSoldTo: {},
      buyingGroupName: "",
      city: "",
      currency: "",
      description: "",
      franchise: "",
      franchiseGroup: "",
      gimBrand: "",
      gimItemType: "",
      gimSubBrand: "",
      invoiceNumber: "",
      invoiceQuantity: 0,
      orderNumber: "",
      reference: "",
      salesAmount: 0.0,
      salesRecognitionDate: "",
      sapg: "",
      subFranchise: "",
      uom: "",
      updatedAt: ""
    },
    defaultItem: {
      customerReported: {},
      customerSoldTo: {},
      buyingGroupName: "",
      city: "",
      currency: "",
      description: "",
      franchise: "",
      franchiseGroup: "",
      gimBrand: "",
      gimItemType: "",
      gimSubBrand: "",
      invoiceNumber: "",
      invoiceQuantity: 0,
      orderNumber: "",
      reference: "",
      salesAmount: 0.0,
      salesRecognitionDate: "",
      sapg: "",
      subFranchise: "",
      uom: "",
      updatedAt: ""
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        customerReported: item.customerReported,
        customerSoldTo: item.customerSoldTo,
        buyingGroupName: item.buyingGroupName,
        city: item.city,
        currency: item.currency,
        description: item.description,
        franchise: item.franchise,
        franchiseGroup: item.franchiseGroup,
        gimBrand: item.gimBrand,
        gimItemType: item.gimItemType,
        gimSubBrand: item.gimSubBrand,
        invoiceNumber: item.invoiceNumber,
        invoiceQuantity: item.invoiceQuantity,
        orderNumber: item.orderNumber,
        reference: item.reference,
        salesAmount: item.salesAmount,
        salesRecognitionDate: item.salesRecognitionDate,
        sapg: item.sapg,
        subFranchise: item.subFranchise,
        uom: item.uom,
        updatedAt: item.updatedAt
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        strykerCommands
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCustommerCommands({ commit }, id) {
      return new Promise((resolve, reject) => {
        strykerCommands
          .getCommands(id)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
