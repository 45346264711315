<template>
  <v-container class="pt-12">
    <v-row class="align-center">
      <v-col cols="3"></v-col>
      <v-col cols="3" class="pr-0">
        <v-card
          max-width="100%"
          height="300"
          color="#feb100"
          style="border-radius: 0px"
        >
          <v-card-title :style="fontStyles.fontFamilyFuturaSubTitle">
            {{ $t("my-commercial") }}
          </v-card-title>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <contact-form class="mt-5"></contact-form>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "ContactView",
  mixins: [fontMixin],
  components: {
    ContactForm
  },
  data() {
    return {};
  }
};
</script>
