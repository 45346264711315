<template>
  <v-container fluid class="container-view">
    <div>
      <!-- todo add search field  and timeline -->
    </div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="9"
        sm="12"
        xl="9"
        smAndDown="12"
        offset-xl="0"
        offset-lg="0"
        offset-sm="0"
        offset-md="0"
      >
        <v-card elevation="9" style="border-radius: 0px">
          <v-card-title
            class="title-background"
            :style="fontStyles.fontFamilyFuturaSubTitle"
          >
            {{ $t("my-cart") }}
          </v-card-title>

          <v-card-text>
            <!-- @/assets/product.png -->
            <v-data-table
              :headers="headers"
              :items="cart"
              density="compact"
              hide-default-footer
              item-key="name"
              :style="fontStyles.fontFamilyHumanist"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.image"
                  :src="require(`@/assets/${item.image}`)"
                  width="50"
                  height="50"
                  contain
                ></v-img>
                <v-img
                  v-else
                  :src="require(`@/assets/svg/package.svg`)"
                  width="50"
                  height="50"
                  contain
                ></v-img>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.price="{ item }">
                {{ parseFloat(item.price).toLocaleString() }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.total="{ item }">
                {{ parseFloat(item.price * item.quantity).toLocaleString() }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-model="item.quantity"
                  solo
                  dense
                  class="mt-2 mb-2"
                  type="number"
                  hide-details
                ></v-text-field>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon size="small" @click="deleteItem(item)">
                  {{ mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="3"
        sm="12"
        xl="3"
        smAndDown="12"
        offset-xl="0"
        offset-lg="0"
        offset-sm="0"
        offset-md="0"
      >
        <v-card elevation="9" style="border-radius: 0px">
          <v-card-title
            class="title-background"
            :style="fontStyles.fontFamilyFuturaSubTitle"
          >
            {{ $t("command-resume") }}
          </v-card-title>
          <v-card-text :style="fontStyles.fontFamilyEgyptienne">
            <v-table class="px-2 py-2 command-table">
              <tbody>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("total") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ this.totalProduct() }} points
                  </td>
                </tr>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("available-points") }} :
                  </td>
                  <td class="text-h7 command-table color-point">
                    {{
                      this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                        ? 0
                        : isDivisibleBy(this.stats.totalPoints, 1000000)
                        ? (this.stats.totalPoints / 1000000).toLocaleString(
                            "fr-FR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          ) + "M"
                        : isDivisibleBy(this.stats.totalPoints, 1000)
                        ? (this.stats.totalPoints / 1000).toLocaleString(
                            "fr-FR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          ) + "K"
                        : this.stats.totalPoints.toLocaleString("fr-FR")
                    }}
                    points
                  </td>
                </tr>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("remaining-amount-to-pay") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ this.remainingAmountToPay() }} € HT
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-select
              label="Adresse de facturation"
              rounded="0"
              v-model="billingAddress"
              :items="addresses"
              item-title="fullAddress"
              item-value="id"
              variant="outlined"
              hide-details
              :style="fontStyles.fontFamilyEgyptienne"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :subtitle="item.fullAddress"
                  :style="fontStyles.fontFamilyEgyptienne"
                ></v-list-item>
              </template>
            </v-select>

            <v-checkbox
              class="pt-1 pb-1"
              v-model="sameAddress"
              label="Adresse de livraison identique à l'adresse de facturation"
              hide-details
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-checkbox>

            <v-select
              label="Adresse de livraison"
              rounded="0"
              v-model="deliveryAddress"
              :items="addresses"
              item-title="fullAddress"
              item-value="id"
              variant="outlined"
              hide-details
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :subtitle="item.fullAddress"
                  :style="fontStyles.fontFamilyEgyptienne"
                ></v-list-item>
              </template>
            </v-select>

            <v-btn
              class="mt-5 text-white"
              rounded="0"
              color="#395d5b"
              variant="elevated"
              block
              @click="checkout()"
              :style="fontStyles.fontFamilyFuturaButton"
            >
              {{ $t("proceed-to-checkout") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <validate-order-dialog
      :modelDialog="dialog"
      @close="closeDialog"
      :deliveryAddress="deliveryAddress"
      :billingAddress="billingAddress"
    ></validate-order-dialog>
  </v-container>
</template>

<script>
import { mdiDelete } from "@mdi/js";
import { mapState } from "vuex";
import ValidateOrderDialog from "@/components/ValidateOrderDialog.vue";
import points from "@/mixins/points";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "CartView",
  mixins: [points, fontMixin],
  components: {
    ValidateOrderDialog
  },
  data() {
    return {
      mdiDelete,
      delivery: 100,
      billingAddress: "",
      deliveryAddress: "",
      dialog: false,
      sameAddress: false,
      headers: [
        { title: "Produit", align: "start", sortable: false, key: "image" },
        {
          title: "Référence",
          align: "start",
          sortable: true,
          key: "product.reference"
        },
        {
          title: "Description",
          align: "start",
          sortable: true,
          key: "product.description"
        },
        {
          title: "Point",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "price"
        },
        {
          title: "Quantité",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "quantity"
        },
        {
          title: "Total",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "total"
        },
        { title: "Actions", align: "end", sortable: true, key: "actions" }
      ]
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart.items.cartItems,
      stats: (state) => state.statsCustomer.statsCustomer,
      addresses: (state) => state.addresses.items
    })
  },
  watch: {
    sameAddress(val) {
      if (val) {
        this.deliveryAddress = this.billingAddress;
      }
    }
  },
  methods: {
    remainingAmountToPay() {
      const totalToReturn = this.totalProduct("int") - this.stats.totalPoints;
      if (totalToReturn < 0) {
        return 0;
      }
      return totalToReturn.toLocaleString();
    },
    closeDialog() {
      this.dialog = false;
    },
    checkout() {
      if (this.deliveryAddress == "" || this.billingAddress == "") {
        this.$store.dispatch("showSnackbar", {
          message: this.$t("alert.error.addresses-empty"),
          color: "error"
        });
      } else if (this.cart.length == 0) {
        this.$store.dispatch("showSnackbar", {
          message: this.$t("alert.error.products-empty"),
          color: "error"
        });
      } else {
        this.dialog = true;
      }
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.cart-item-remove-confirm"))) {
        this.$store
          .dispatch("cart/deleteItem", item.cartItemId)
          .then(() => {
            const index = this.cart.findIndex(
              (cartItem) => cartItem.cartItemId === item.cartItemId
            );
            if (index !== -1) {
              this.cart.splice(index, 1);
            }
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.cart-item-removed"),
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.cart-item-removed"),
              color: "error"
            });
          });
      }
    },
    totalProduct(type) {
      var totalP = 0;
      this.cart.forEach((item) => {
        totalP += item.price * item.quantity;
      });
      if (type == "int") {
        return totalP;
      }
      return totalP.toLocaleString();
    }
  },
  created() {}
};
</script>

<style>
.command-table {
  border-bottom: none !important;
}
</style>
