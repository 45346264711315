import subFranchises from "../../api/subFranchises";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    editedItem: {
      name: "",
      updatedAt: null,
      updatedBy: null
    },
    defaultItem: {
      name: "",
      updatedAt: null,
      updatedBy: null
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        name: item.name,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedBy
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        subFranchises
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        subFranchises
          .store(state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        subFranchises
          .update(state.editedId, state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //eslint-disable-next-line
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        subFranchises
          .delete(id)
          .then((response) => {
            // dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
