import axios from "axios";

export default {
  login(data) {
    return axios.post("/api/Authenticate/login", data);
  },
  resetPassword(data) {
    return axios.post("/api/Authenticate/resetpassword", data);
  },
  askresetPassword(email) {
    return axios.post("/api/Authenticate/askresetpassword", email);
  }
};
