<template>
  <v-container class="container-view" fluid>
    <v-row class="welcome-container d-flex align-center">
      <v-col cols="7" class="ml-3 welcome-title-col">
        <h1 class="welcome-client" :style="fontStyles.fontFamilyFutura">
          {{ $t("welcome-on-your-space") }}
        </h1>
      </v-col>
      <v-col cols="5" class="">
        <div class="pl-5" :style="fontStyles.fontFamilyFutura">
          {{ this.customer.name }}
        </div>
        <div class="pl-5">
          <span :style="fontStyles.fontFamilyEgyptienne">
            Statut
            {{
              this.customer.status.number == "" ||
              this.customer.status.number < 0 ||
              this.customer.status.number == null
                ? "0"
                : this.customer.status.number
            }}
            -
          </span>
          <span class="color-point" :style="fontStyles.fontFamilyEgyptienne">
            {{
              this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                ? 0
                : isDivisibleBy(this.stats.totalPoints, 1000000)
                ? (this.stats.totalPoints / 1000000).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + "M"
                : isDivisibleBy(this.stats.totalPoints, 1000)
                ? (this.stats.totalPoints / 1000).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + "K"
                : this.stats.totalPoints.toLocaleString("fr-FR")
            }}
            points restants
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="pb-8 pt-0">
      <v-col
        cols="12"
        md="8"
        lg="8"
        sm="8"
        xl="8"
        smAndDown="12"
        offset-xl="0"
        offset-lg="0"
        offset-sm="2"
        offset-md="2"
      >
        <v-card
          class="mx-auto text-center pl-0 pr-0"
          color="white"
          max-width="99%"
          dark
          elevation="6"
          style="border-radius: 0px"
        >
          <v-card-title
            class="title-background text-left"
            :style="fontStyles.fontFamilyFuturaSubTitle"
          >
            {{ $t("some-data") }}
          </v-card-title>
          <v-card-text class="pl-0 pr-0 pb-0">
            <first-carousel-item></first-carousel-item>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="4"
        sm="8"
        xl="4"
        offset-xl="0"
        offset-lg="0"
        offset-sm="2"
        offset-md="2"
      >
        <v-card
          class="mx-auto text-center pl-0 pr-0"
          color="white"
          max-width="99%"
          dark
          elevation="6"
          style="border-radius: 0px"
        >
          <v-card-title
            class="title-background text-left"
            :style="fontStyles.fontFamilyFuturaSubTitle"
          >
            {{ $t("ca-by-sub-franchise", { year: new Date().getFullYear() }) }}
          </v-card-title>
          <v-card-text class="pl-0 pr-0 pb-0">
            <second-carousel-item :isSplited="true"></second-carousel-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- PUBS -->
    <!-- <v-row class="pub-container pb-8" no-gutters>
      <v-col cols="12">
        <v-img src="@/assets/svg/PUB.svg" class="pub-container" cover> </v-img>
      </v-col>
    </v-row> -->
    <v-dialog max-width="500" v-model="dialogValidation" persistent>
      <v-card title="Conditions générales" style="border-radius: 0px">
        <v-card-text>
          {{ this.termsAndConditions.fullText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Refuser" @click="setDialogValidation('no')"></v-btn>
          <v-btn text="Accepter" @click="setDialogValidation('yes')"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import FirstCarouselItem from "@/components/FirstCarouselItem.vue";
import SecondCarouselItem from "@/components/SecondCarouselItem.vue";
import points from "@/mixins/points";
import { mapState } from "vuex";
import {
  mdiStarOutline,
  mdiPackageVariant,
  mdiAccountBoxOutline,
  mdiCogOutline,
  mdiForumOutline
} from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";

export default defineComponent({
  name: "HomeView",
  mixins: [points, fontMixin],
  components: {
    FirstCarouselItem,
    SecondCarouselItem
  },
  data() {
    return {
      mdiStarOutline,
      mdiPackageVariant,
      mdiAccountBoxOutline,
      mdiCogOutline,
      mdiForumOutline,
      dialogValidation: false
    };
  },
  created() {
    this.$store
      .dispatch(
        "termsAndConditions/userAccepted",
        this.$store.state.users.user.id
      )
      .then((response) => {
        if (response.data == false) {
          this.$store
            .dispatch("termsAndConditions/getCurrent")
            .then(() => {
              this.dialogValidation = true;
            })
            .catch(() => {
              // todo
            });
        }
      })
      .catch(() => {
        // todo
      });
  },
  methods: {
    setDialogValidation(value) {
      if (value == "no") {
        this.logout();
        this.dialogValidation = false;
      } else {
        const data = {
          userId: this.$store.state.users.user.id,
          termVersionId: this.termsAndConditionsId,
          acceptanceDate: new Date().toISOString()
        };
        this.$store
          .dispatch("termsAndConditions/userConfirmation", data)
          .then(() => {
            this.dialogValidation = false;
          })
          .catch(() => {
            this.dialogValidation = false;
            // todo
          });
      }
    },
    logout() {
      this.$store.commit("auth/clearAuthData");
      this.$store.dispatch("users/resetSelectedCustomer");
      this.$router.push({ name: "login" });
    },
    pushView(view) {
      if (this.$route.name != view) {
        this.$router.push({ name: view });
      }
    }
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer,
      termsAndConditionsId: (state) => state.termsAndConditions.editedId,
      termsAndConditions: (state) => state.termsAndConditions.editedItem
    })
  }
});
</script>

<style>
.pub-container {
  max-height: 275px;
}

.welcome-container {
  max-height: 275px;
  margin-bottom: 20px;
}

.welcome-title-col {
  flex: 0 0 56% !important;
  color: white;
  background-color: #ffb500;
}

.color-point {
  color: #ffb500;
}

.container-view {
  margin-top: 30px;
}

.title-background {
  background-color: #535756;
  color: white;
}
</style>
