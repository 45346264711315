import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/gimItemTypes${paginationString.text}`);
    } else {
      return axios.get("/api/gimItemTypes");
    }
  },
  store(gimItemType) {
    return axios.post("/api/gimItemTypes", gimItemType);
  },
  delete(id) {
    return axios.delete(`/api/gimItemTypes/${id}`);
  },
  update(id, gimItemType) {
    return axios.put(`/api/gimItemTypes/${id}`, gimItemType);
  }
};
