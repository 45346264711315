import addresses from "../../api/addresses";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    editedItem: {
      Id: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      fullAddress: "",
      addressName: "",
      addressType: "0",
      fK_Customer: null
    },
    defaultItem: {
      Id: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      fullAddress: "",
      addressName: "",
      addressType: "0",
      fK_Customer: null
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        Id: item.id,
        addressLine1: item.addressLine1,
        addressLine2: item.addressLine2,
        city: item.city,
        state: item.state,
        zipCode: item.zipCode,
        country: item.country,
        fullAddress: item.fullAddress,
        addressName: item.addressName,
        addressType: "" + item.addressType + "",
        fK_Customer: item.fK_Customer
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        addresses
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchItemByCustomerId({ commit }, data) {
      return new Promise((resolve, reject) => {
        addresses
          .getAddressByCustomerId(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const data = state.editedItem;
        data.addressType = parseInt(data.addressType);

        addresses
          .store(state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const data = state.editedItem;
        data.addressType = parseInt(data.addressType);

        addresses
          .update(state.editedId, state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //eslint-disable-next-line
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        addresses
          .delete(id)
          .then((response) => {
            // dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
