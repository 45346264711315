import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/sapgCustoms${paginationString.text}`);
    } else {
      return axios.get("/api/sapgCustoms");
    }
  },
  store(sapgCustom) {
    return axios.post("/api/sapgCustoms", sapgCustom);
  },
  delete(id) {
    return axios.delete(`/api/sapgCustoms/${id}`);
  },
  update(id, sapgCustom) {
    return axios.put(`/api/sapgCustoms/${id}`, sapgCustom);
  }
};
