<template>
  <v-card elevation="6" style="border-radius: 0px">
    <v-card-title :style="fontStyles.fontFamilyFuturaSubTitle">
      {{ this.$t("contact-us") }}
    </v-card-title>
    <v-card-text>
      <form @submit.prevent="submit">
        <v-row>
          <v-col cols="6">
            <v-text-field
              hide-details
              rounded="0"
              class="border-orange"
              v-model="nom"
              :counter="10"
              :label="this.$t('name')"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              hide-details
              rounded="0"
              class="border-orange"
              v-model="prenom"
              :counter="10"
              :label="this.$t('firstname')"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              hide-details
              rounded="0"
              class="border-orange"
              v-model="select"
              :items="items"
              :label="this.$t('select-an-object')"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              rounded="0"
              v-model="message"
              :label="this.$t('message')"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-textarea>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        rounded="0"
        variant="flat"
        color="#395d5b"
        :prepend-icon="mdiSend"
        class="mb-5"
        :style="fontStyles.fontFamilyFuturaButton"
      >
        {{ $t("send") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiSend } from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "ContactForm",
  mixins: [fontMixin],
  data() {
    return {
      mdiSend,
      nom: "",
      prenom: "",
      message: "",
      items: ["Alpha", "Bravo", "Charlie", "Delta"],
      select: null
    };
  }
};
</script>

<style>
.border-orange {
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}
</style>
