<template>
  <v-dialog v-model="importDialog" max-width="800px">
    <template v-slot:activator="{ props }">
      <v-btn
        variant="elevated"
        class="mb-2 text-white mr-2"
        color="#ffb300"
        :prepend-icon="mdiFileImport"
        dark
        v-bind="props"
      >
        {{ $t("admin.import") }}
      </v-btn>
    </template>

    <v-card style="border-radius: 0px">
      <v-card-title>
        <span class="text-h5">{{ $t("admin.import") }}</span>
      </v-card-title>
      <v-card-text>
        <v-file-input
          density="compact"
          class="pt-5 pr-5"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :label="this.$t('select-file')"
          @change="handleFileChange"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="importDialog = !importDialog"
          color="#ffb300"
          class="text-white"
          variant="elevated"
        >
          {{ $t("back") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="saveFile"
          color="#ffb300"
          class="text-white"
          variant="elevated"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiFileImport } from "@mdi/js";
export default {
  name: "ImportFileDialog",
  props: ["table"],
  data() {
    return {
      mdiFileImport,
      importDialog: false,
      file: null
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    async saveFile() {
      this.$store.commit("setOverlay", true);
      if (!this.file) {
        this.$store.dispatch("showSnackbar", {
          message: this.$t("errors.no-file-selected"),
          color: "error"
        });
        this.$store.commit("setOverlay", false);
        return;
      }

      const formData = new FormData();
      formData.append("file", this.file);

      formData.append("table", this.table);
      this.$store
        .dispatch("importFile", formData)
        .then(() => {
          this.$store.commit("setOverlay", false);
          this.$store.dispatch("showSnackbar", {
            message: this.$t("success.file-upload-success"),
            color: "success"
          });
          this.importDialog = false;
        })
        .catch(() => {
          this.$store.commit("setOverlay", false);
        });
    }
  }
};
</script>
