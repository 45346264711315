<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card
      :prepend-icon="mdiAccount"
      :title="$t('admin.stats-customer')"
      style="border-radius: 0px"
    >
      <v-card-text>
        <first-carousel-item></first-carousel-item>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :text="$t('close')"
          variant="plain"
          @click="$emit('close')"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import FirstCarouselItem from "../FirstCarouselItem.vue";
export default {
  name: "ShowStatsCustomerAdmin",
  props: ["modelDialog"],
  components: { FirstCarouselItem },
  data() {
    return {
      mdiAccount
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
