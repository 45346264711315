import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/franchiseGroups${paginationString.text}`);
    } else {
      return axios.get("/api/franchiseGroups");
    }
  },
  store(franchiseGroup) {
    return axios.post("/api/franchiseGroups", franchiseGroup);
  },
  delete(id) {
    return axios.delete(`/api/franchiseGroups/${id}`);
  },
  update(id, franchiseGroup) {
    return axios.put(`/api/franchiseGroups/${id}`, franchiseGroup);
  }
};
