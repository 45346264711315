import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/products${paginationString.text}`);
    } else {
      return axios.get("/api/products");
    }
  },
  store(product) {
    return axios.post("/api/products", product);
  },
  delete(id) {
    return axios.delete(`/api/products/${id}`);
  },
  update(id, product) {
    return axios.put(`/api/products/${id}`, product);
  }
};
