<template>
  <v-container class="container-view">
    <v-card elevation="6" style="border-radius: 0px">
      <v-card-title
        class="title-background"
        :style="fontStyles.fontFamilyFuturaSubTitle"
      >
        {{ $t("my-contracts") }}
      </v-card-title>
      <v-card-text>
        <div>
          <v-data-table
            :items="contracts"
            :style="fontStyles.fontFamilyHumanist"
          >
            <!-- eslint-disable-next-line-->
            <template v-slot:item.file="{ item }">
              <v-icon
                :icon="item.file.type == 'PDF' ? mdiFilePdfBox : mdiFileWordBox"
                size="x-large"
              ></v-icon>
              {{ item.file.nom }}
            </template>
            <!-- eslint-disable-next-line-->
            <template v-slot:item.actions="{ item }">
              <v-icon
                :icon="mdiDownload"
                @click="downloadFile(item.file.path)"
                size="x-large"
              >
              </v-icon>
            </template>
            <!-- eslint-disable-next-line-->
            <template v-slot:item.size="{ item }">
              <span>
                {{ item.size }}
              </span>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiFilePdfBox, mdiFileWordBox, mdiDownload } from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "ContratView",
  mixins: [fontMixin],
  data() {
    return {
      mdiFilePdfBox,
      mdiFileWordBox,
      mdiDownload,
      contracts: [
        {
          file: {
            type: "PDF",
            nom: "Mon contrat",
            path: ""
          },
          size: "4MB",
          Uploader: "Justine",
          actions: ""
        }
      ]
    };
  },
  methods: {
    downloadFile(file) {
      return file;
    }
  }
};
</script>
