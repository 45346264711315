import termsAndConditions from "../../api/termsAndConditions";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    accepted: false,
    editedItem: {
      versionNumber: "",
      versionDate: "",
      fullText: "",
      changeDescription: "",
      isActive: true
    },
    defaultItem: {
      versionNumber: "",
      versionDate: "",
      fullText: "",
      changeDescription: "",
      isActive: true
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setAccepted(state, acceptedOrNot) {
      state.accepted = acceptedOrNot;
    },
    setEditedItem(state, item) {
      const editedItem = {
        versionNumber: item.versionNumber,
        versionDate: item.versionDate,
        fullText: item.fullText,
        changeDescription: item.changeDescription,
        isActive: item.isActive
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        termsAndConditions
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    userAccepted({ commit }, data) {
      return new Promise((resolve, reject) => {
        termsAndConditions
          .acceptedOrNot(data)
          .then((response) => {
            commit("setAccepted", response.data);
            resolve(response);
          })
          .catch((error) => {
            console.log("error : ", error);
            reject(error);
          });
      });
    },
    getCurrent({ commit }) {
      return new Promise((resolve, reject) => {
        termsAndConditions
          .getCurrent()
          .then((response) => {
            commit("setEditedItem", response.data);
            commit("setEditedId", response.data.id);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    userConfirmation({ commit }, data) {
      return new Promise((resolve, reject) => {
        termsAndConditions
          .acceptConditions(data)
          .then((response) => {
            commit("setAccepted", true);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const data = {
          versionNumber: state.editedItem.versionNumber,
          fullText: state.editedItem.fullText,
          changeDescription: state.editedItem.changeDescription,
          isActive: state.editedItem.isActive
        };
        termsAndConditions
          .store(data)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        termsAndConditions
          .update(state.editedId, state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
