<template>
  <v-container fluid class="container-view">
    <v-row class="pt-5">
      <v-col
        cols="12"
        md="8"
        lg="4"
        sm="8"
        xl="4"
        offset-xl="0"
        offset-lg="0"
        offset-sm="2"
        offset-md="2"
        :class="{ 'total-points-sheet': isInCarousel }"
      >
        <div v-if="!isInCarousel" class="div-sheet-points">
          <v-sheet
            elevation="8"
            class="d-flex align-center justify-center flex-wrap text-center"
            height="200"
            width="250"
          >
            <div>
              <h2
                class="text-h2 font-weight-black text-color"
                :style="fontStyles.fontFamilyFutura"
              >
                {{
                  this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                    ? 0
                    : isDivisibleBy(this.stats.totalPoints, 1000000)
                    ? (this.stats.totalPoints / 1000000).toLocaleString(
                        "fr-FR",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      ) + "M"
                    : isDivisibleBy(this.stats.totalPoints, 1000)
                    ? (this.stats.totalPoints / 1000).toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }) + "K"
                    : this.stats.totalPoints.toLocaleString("fr-FR")
                }}
              </h2>

              <div
                class="text-body-2 mt-2"
                :style="fontStyles.fontFamilyEgyptienne"
              >
                {{
                  this.$t("total-pts-year", {
                    year: new Date().getFullYear()
                  })
                }}
              </div>
            </div>
          </v-sheet>
        </div>
        <div
          v-else
          class="d-flex align-center justify-center flex-wrap text-center"
        >
          <v-sheet
            elevation="8"
            class="d-flex align-center justify-center flex-wrap text-center"
            height="200"
            width="250"
          >
            <div>
              <h2
                class="text-h2 font-weight-black text-color"
                :style="fontStyles.fontFamilyFutura"
              >
                {{
                  this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                    ? 0
                    : isDivisibleBy(this.stats.totalPoints, 1000000)
                    ? (this.stats.totalPoints / 1000000).toLocaleString(
                        "fr-FR",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      ) + "M"
                    : isDivisibleBy(this.stats.totalPoints, 1000)
                    ? (this.stats.totalPoints / 1000).toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }) + "K"
                    : this.stats.totalPoints.toLocaleString("fr-FR")
                }}
              </h2>

              <div
                class="mt-2"
                :style="fontStyles.fontFamilyEgyptienneSubTitle"
              >
                {{
                  this.$t("total-pts-year", {
                    year: new Date().getFullYear()
                  })
                }}
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="8"
        lg="8"
        sm="12"
        xl="8"
        offset-xl="0"
        offset-lg="0"
        offset-sm="0"
        offset-md="2"
      >
        <div>
          <v-sheet
            class="first-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col
                cols="2"
                class="pr-0 d-flex align-center justify-center flex-wrap text-center"
              >
                <h2 class="text-h6" :style="fontStyles.fontFamilyFutura">
                  {{ new Date().getFullYear() - 1 }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                class="border-opacity-100 divider-height"
                vertical
              ></v-divider>
              <v-col>
                <h2
                  class="text-h5 text-center"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  Statut {{ this.stats.statusPastYear }} -
                  {{
                    this.stats.totalPastYear == 0 ||
                    this.stats.totalPastYear < 0
                      ? 0
                      : isDivisibleBy(this.stats.totalPastYear, 1000000)
                      ? (this.stats.totalPastYear / 1000000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "M"
                      : isDivisibleBy(this.stats.totalPastYear, 1000)
                      ? (this.stats.totalPastYear / 1000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "K"
                      : this.stats.totalPastYear.toLocaleString("fr-FR")
                  }}€ ventes {{ new Date().getFullYear() - 1 }}
                </h2>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <div>
          <v-sheet
            class="second-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col
                cols="2"
                class="pr-0 d-flex align-center justify-center flex-wrap text-center"
              >
                <h2 class="text-h6" :style="fontStyles.fontFamilyFutura">
                  {{ new Date().getFullYear() }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                length="60"
                class="border-opacity-100 divider-center"
                vertical
              ></v-divider>
              <v-col>
                <h2
                  class="text-h6 text-center"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  Statut {{ this.customer.status.number }} -
                  {{
                    this.stats.totalCurrentYear == 0 ||
                    this.stats.totalCurrentYear < 0
                      ? 0
                      : isDivisibleBy(this.stats.totalCurrentYear, 1000000)
                      ? (this.stats.totalCurrentYear / 1000000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "M"
                      : isDivisibleBy(this.stats.totalCurrentYear, 1000)
                      ? (this.stats.totalCurrentYear / 1000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "K"
                      : this.stats.totalCurrentYear.toLocaleString("fr-FR")
                  }}€ ventes
                  {{ new Date().getFullYear() }}
                </h2>
                <h5
                  class="text-caption"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  {{ this.$t("projection") }}
                </h5>
                <v-row
                  class="d-flex align-center justify-center flex-wrap text-center"
                >
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-slider
                      v-model="statusProgress"
                      :min="0"
                      :max="4"
                      :ticks="this.tickLabelsStatus"
                      color="#ffb500"
                      show-ticks="always"
                      tick-size="4"
                    >
                      <template v-slot:tick-label="{ tick }">
                        <p
                          class="text-caption"
                          :style="fontStyles.fontFamilyEgyptienneSubTitle"
                        >
                          {{ tick.label }}
                        </p>
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="3" sm="6" xl="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2
                  class="text-h4 font-weight-black text-color"
                  :style="fontStyles.fontFamilyFutura"
                >
                  {{
                    this.stats.capitalPoints == 0 ||
                    this.stats.capitalPoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.capitalPoints, 1000000)
                      ? (this.stats.capitalPoints / 1000000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "M"
                      : isDivisibleBy(this.stats.capitalPoints, 1000)
                      ? (this.stats.capitalPoints / 1000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "K"
                      : this.stats.capitalPoints.toLocaleString("fr-FR")
                  }}
                </h2>

                <div
                  class="mt-2"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("capital") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" lg="3" sm="6" xl="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-divider
            :thickness="1"
            class="border-opacity-50"
            vertical
          ></v-divider>
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2
                  class="text-h4 font-weight-black text-color"
                  :style="fontStyles.fontFamilyFutura"
                >
                  {{
                    this.stats.consommablePoints == 0 ||
                    this.stats.consommablePoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.consommablePoints, 1000000)
                      ? (this.stats.consommablePoints / 1000000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "M"
                      : isDivisibleBy(this.stats.consommablePoints, 1000)
                      ? (this.stats.consommablePoints / 1000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "K"
                      : this.stats.consommablePoints.toLocaleString("fr-FR")
                  }}
                </h2>

                <div
                  class="mt-2"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("consommable") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" lg="3" sm="6" xl="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-divider
            :thickness="1"
            class="border-opacity-50"
            vertical
          ></v-divider>
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2
                  class="text-h4 font-weight-black text-color"
                  :style="fontStyles.fontFamilyFutura"
                >
                  {{
                    this.stats.implantPoints == 0 ||
                    this.stats.implantPoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.implantPoints, 1000000)
                      ? (this.stats.implantPoints / 1000000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "M"
                      : isDivisibleBy(this.stats.implantPoints, 1000)
                      ? (this.stats.implantPoints / 1000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "K"
                      : this.stats.implantPoints.toLocaleString("fr-FR")
                  }}
                </h2>

                <div
                  class="mt-2"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("implant") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" lg="3" sm="6" xl="3" class="pl-0 pr-0">
        <v-row
          class="px-0 py-0 mx-0 my-0 d-flex align-center justify-center flex-wrap text-center"
        >
          <v-divider
            :thickness="1"
            class="border-opacity-50"
            vertical
          ></v-divider>
          <v-col cols="11" class="px-0 py-0 mx-0 my-0">
            <div
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <div>
                <h2
                  class="text-h4 font-weight-black text-color"
                  :style="fontStyles.fontFamilyFutura"
                >
                  {{
                    this.stats.servicePoints == 0 ||
                    this.stats.servicePoints < 0
                      ? 0
                      : isDivisibleBy(this.stats.servicePoints, 1000000)
                      ? (this.stats.servicePoints / 1000000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "M"
                      : isDivisibleBy(this.stats.servicePoints, 1000)
                      ? (this.stats.servicePoints / 1000).toLocaleString(
                          "fr-FR",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        ) + "K"
                      : this.stats.servicePoints.toLocaleString("fr-FR")
                  }}
                </h2>

                <div
                  class="mt-2"
                  :style="fontStyles.fontFamilyEgyptienneSubTitle"
                >
                  {{ this.$t("points") }} {{ new Date().getFullYear() }}
                  {{ this.$t("services") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import points from "@/mixins/points";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "FirstCarouselItem",
  mixins: [points, fontMixin],
  props: {
    isInCarousel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      color: "indigo",
      slide: "First",
      tickLabels: {
        0: "Aucun Statut",
        400000: "Statut 1",
        700000: "Statut 2",
        1200000: "Statut 3",
        2100000: "Statut 4"
      }
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer
    }),

    tickLabelsStatus() {
      const isSmallScreen = this.$vuetify.display.mdAndDown; // sm (≤600px) ou plus petit
      return {
        0: isSmallScreen ? "Aucun" : "Aucun statut",
        1: isSmallScreen ? "St.1" : "Statut 1",
        2: isSmallScreen ? "St.2" : "Statut 2",
        3: isSmallScreen ? "St.3" : "Statut 3",
        4: isSmallScreen ? "St.4" : "Statut 4"
      };
    },

    statusProgress: {
      get() {
        var value = (
          this.$store.state.statsCustomer.statsCustomer.totalCurrentYear /
          1000000
        ).toFixed(2);
        this.scaleValue(value);
        return this.scaleValue(value);
      }
    }
  },
  methods: {
    scaleValue(value) {
      const min = 0;
      const max = 2.1;
      const newMin = 0;
      const newMax = 4;
      return ((value - min) * (newMax - newMin)) / (max - min) + newMin;
    }
  }
};
</script>

<style scoped>
.div-sheet-points {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-text-statut {
  text-align: center;
}

.first-sheet-carousel-command {
  margin-bottom: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.second-sheet-carousel-command {
  margin-top: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.total-points-sheet {
  padding-left: 50px;
}

.text-color {
  color: #feb100;
}

.divider-center {
  align-self: center;
}
</style>
