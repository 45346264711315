<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.customer-number') + ' *'"
                v-model="editedItem.number"
                :rules="numberRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.customer-name') + ' *'"
                v-model="editedItem.name"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('email')"
                v-model="editedItem.email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('phone')"
                v-model="editedItem.phone"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.buying-group')"
                v-model="editedItem.buyingGroup"
                :items="buyingGroups"
                @update:search="debouncedUpdateBuyingGroups"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('statut')"
                v-model="editedItem.fK_Status"
                required
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('admin.updated-at')"
                disabled
                :model-value="
                  editedItem.updatedAt != null
                    ? formatedDate(editedItem.updatedAt)
                    : ''
                "
                required
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="4" sm="6">
              <v-checkbox
                label="Actif"
                color="#ffb500"
                v-model="editedItem.active"
                :true-value="true"
                :false-value="false"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.customers')"
                v-model="customerRelated"
                :items="customers"
                @update:search="debouncedUpdateCustomers"
                item-title="number"
                return-object
                multiple
                show-select
                variant="outlined"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                :label="$t('admin.exclusive-interlocutor')"
                v-model="exclusiveInterlocutor"
                :items="users"
                item-title="firstName"
                item-value="id"
                clearable
                variant="outlined"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <small class="text-caption text-medium-emphasis">{{
            $t("require-fields")
          }}</small>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil, mdiAccount } from "@mdi/js";
import paginations from "@/mixins/paginations";
import { mapState } from "vuex";
import _ from "lodash"; // Importer lodash

export default {
  name: "AddEditCustomer",
  props: ["modelDialog"],
  mixins: [paginations],
  data() {
    return {
      mdiPencil,
      mdiAccount,
      numberRules: [
        (value) => {
          if (value) return true;
          return "Customer number is mandatory";
        }
      ],
      nameRules: [
        (value) => {
          if (value) return true;
          return "Customer name is mandatory";
        }
      ]
    };
  },
  created() {
    // Déclarer une méthode débouncée
    this.debouncedUpdateBuyingGroups = _.debounce(this.updateBuyingGroups, 300);
    // Déclarer une méthode débouncée
    this.debouncedUpdateCustomers = _.debounce(this.updateCustomers, 300);
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.clients.editedId,
      buyingGroups: (state) => state.buyingGroups.items,
      customers: (state) => state.clients.secondItemsList,
      users: (state) => state.users.items
    }),
    editedItem: {
      get() {
        return this.$store.state.clients.editedItem;
      },
      set(value) {
        this.$store.commit("clients/setEditedItem", value);
      }
    },
    exclusiveInterlocutor: {
      get() {
        return (
          this.users.find(
            (user) => user.id === this.editedItem.fK_UniqueContact
          ) || null
        );
      },
      set(value) {
        this.$store.commit("clients/setExclusiveInterlocutor", value);
      }
    },
    customerRelated: {
      get() {
        var customerRelated = [];
        if (
          this.$store.state.clients.editedItem.customerRelationDto &&
          this.$store.state.clients.editedItem.customerRelationDto.length > 0
        ) {
          this.$store.state.clients.editedItem.customerRelationDto.forEach(
            (item) => {
              customerRelated.push(item.relatedCustomer);
            }
          );
        }
        return customerRelated;
      },
      set(value) {
        this.$store.commit("clients/addOrRemoveACustomerRelation", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-customer")
        : this.$t("admin.edit-customer");
    }
  },
  methods: {
    updateBuyingGroups(item) {
      this.searchBack(item, 500, "buyingGroups", false);
    },
    updateCustomers(item) {
      this.searchBackSecondStore(item, 500, "clients", false);
    },
    formatedDate(inputDate) {
      // Convertir en objet Date
      const date = new Date(inputDate);

      // Extraire le jour, le mois et l'année
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
      const year = date.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.isValid;
      if (valid) {
        if (this.editedId === -1) {
          this.editedItem.fK_Status = -1;
          this.$store
            .dispatch("clients/saveEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.customer-create"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.customer-create"),
                color: "error"
              });
              this.close();
            });
        } else {
          this.$store
            .dispatch("clients/updateEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.customer-update"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.customer-update"),
                color: "error"
              });
              this.close();
            });
        }
      }
    }
  }
};
</script>
