<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="customers"
      :items-length="totalItems"
      :search="search"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            Nouveau client
          </v-btn>
          <import-file-dialog :table="'customers'"></import-file-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status.number="{ item }">
        <v-chip color="#ffb300" dark>
          {{ item.status.number == -1 ? 0 : item.status.number }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon class="me-2" size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
        <v-tooltip
          location="top"
          text="Lié ce client à un ou plusieurs utilisateur(s)"
        >
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="me-2"
              size="large"
              @click="linkedUser(item)"
            >
              {{ mdiAccountGroup }}
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Ajouter ou retirer des points">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="me-2"
              size="large"
              @click="addOrRemovePoints(item)"
            >
              {{ mdiPlusMinusBox }}
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Afficher le compte client">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class=""
              size="large"
              @click="showCustomer(item)"
            >
              {{ mdiAccountArrowRight }}
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Recalculer les points du client">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="me-2"
              size="large"
              @click="recalculatePoints(item)"
            >
              {{ mdiRefreshCircle }}
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Afficher les points du clients">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="me-2"
              size="large"
              @click="getCustomerStats(item)"
            >
              {{ mdiCashMultiple }}
            </v-icon>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'clients'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <show-stats-customer-admin
      :modelDialog="statsCustomerDialog"
      @close="statsCustomerDialog = false"
    ></show-stats-customer-admin>
    <add-edit-customer
      :modelDialog="customerDialog"
      @close="closeDialog"
    ></add-edit-customer>
    <user-customer-dialog
      :modelDialog="userCustomerDialog"
      @close="closeUserCustomerDialog"
    ></user-customer-dialog>
    <add-or-remove-customer-points-dialog
      :modelDialog="addOrRemoveCustomerPointsDialog"
      @close="closeAddOrRemoveCustomerPointsDialog"
    ></add-or-remove-customer-points-dialog>
  </v-container>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiAccountPlus,
  mdiAccountArrowRight,
  mdiAccountGroup,
  mdiRefreshCircle,
  mdiPlusMinusBox,
  mdiCashMultiple
} from "@mdi/js";
import AddEditCustomer from "./admin/AddEditCustomer.vue";
import UserCustomerDialog from "./admin/UserCustomerDialog.vue";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import ImportFileDialog from "./ImportFileDialog.vue";
import ShowStatsCustomerAdmin from "./admin/ShowStatsCustomerAdmin.vue";
import AddOrRemoveCustomerPointsDialog from "./admin/AddOrRemoveCustomerPointsDialog.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "ClientAdmin",
  components: {
    ImportFileDialog,
    PaginationDynamic,
    AddEditCustomer,
    UserCustomerDialog,
    ShowStatsCustomerAdmin,
    AddOrRemoveCustomerPointsDialog
  },
  mixins: [paginate],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiAccountPlus,
      mdiAccountArrowRight,
      mdiAccountGroup,
      mdiRefreshCircle,
      mdiCashMultiple,
      mdiPlusMinusBox,
      customerDialog: false,
      userCustomerDialog: false,
      statsCustomerDialog: false,
      addOrRemoveCustomerPointsDialog: false,
      headers: [
        {
          title: this.$t("admin.customer-number"),
          align: "start",
          sortable: false,
          key: "number"
        },
        { title: this.$t("admin.customer-name"), key: "name" },
        { title: this.$t("city"), key: "city" },
        { title: this.$t("address"), key: "address" },
        { title: this.$t("statut"), key: "status.number" },
        // { title: this.$t("points"), key: "points" },
        {
          title: this.$t("actions"),
          align: "center",
          key: "actions",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.clients.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount,
      editedId: (state) => state.clients.editedId
    }),
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    addOrRemovePoints(item) {
      const data = {
        id: item.id,
        name: ""
      };
      this.$store
        .dispatch("statsCustomer/fetChSpecificStatsCustomer", data)
        .then(() => {
          this.$store.commit("clients/setEditedId", item.id);
          this.$store.commit("clients/setEditedItem", Object.assign({}, item));
          this.addOrRemoveCustomerPointsDialog = true;
        })
        .catch(() => {
          this.$store.dispatch("showSnackbar", {
            message: this.$t("alert.error.customer-stats-failed"),
            color: "error"
          });
        });
    },
    closeAddOrRemoveCustomerPointsDialog() {
      this.$store.commit("clients/setEditedId", -1);
      this.$store.commit("clients/setEditedItem", Object.assign({}, {}));
      this.addOrRemoveCustomerPointsDialog = false;
    },
    closeDialog() {
      this.customerDialog = false;
      // this.$store.dispatch("clients/resetEditedItem");
    },
    getCustomerStats(item) {
      const data = {
        id: item.id,
        name: "admin"
      };
      this.$store
        .dispatch("statsCustomer/fetChSpecificStatsCustomer", data)
        .then((response) => {
          if (response.data !== "") {
            this.statsCustomerDialog = true;
          } else {
            // todo disabled actions
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.customer-stats"),
              color: "warning"
            });
          }
        });
    },
    recalculatePoints(item) {
      this.$store
        .dispatch("statsCustomer/recalculatePoints", item.id)
        .then((response) => {
          if (response.data !== "") {
            this.statsCustomerDialog = true;
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.recalculate-points"),
              color: "success"
            });
          } else {
            // todo disabled actions
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.customer-stats"),
              color: "warning"
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("showSnackbar", {
            message: this.$t("alert.error.recalculate-points"),
            color: "error"
          });
        });
    },
    closeUserCustomerDialog() {
      this.$store.commit("clients/setEditedId", -1);
      this.$store.commit("clients/setUsers", []);
      this.$store.commit("clients/setEditedItem", Object.assign({}, {}));
      this.userCustomerDialog = false;
    },
    showCustomer(item) {
      const data = {
        id: item.id,
        name: ""
      };
      this.$store
        .dispatch("statsCustomer/fetChSpecificStatsCustomer", data)
        .then((response) => {
          if (response.data !== "") {
            this.$store
              .dispatch("cart/fetchCartByCustomer", item.id)
              .then(() => {
                this.$router.push({ name: "home" });
              });
          } else {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.customer-stats"),
              color: "warning"
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("showSnackbar", {
            message: this.$t("alert.error.customer-stats-failed"),
            color: "error"
          });
        });
    },
    linkedUser(item) {
      this.$store.commit("clients/setEditedId", item.id);
      this.$store.commit("clients/setEditedItem", Object.assign({}, item));
      this.$store
        .dispatch("users/fetchAll")
        .then(() => {
          this.$store
            .dispatch("clients/getLinkedUsers", this.editedId)
            .then(() => {
              this.userCustomerDialog = true;
            })
            .catch(() => {
              this.$store.commit("clients/setEditedId", -1);
              this.$store.commit(
                "clients/setEditedItem",
                Object.assign({}, {})
              );
            });
        })
        .catch(() => {
          this.$store.commit("clients/setEditedId", -1);
          this.$store.commit("clients/setEditedItem", Object.assign({}, {}));
        });
    },
    toSort(item) {
      this.sortBy(item, "clients");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "clients");
    },
    openDialog() {
      this.$store.commit("clients/setEditedId", -1);
      this.$store.commit("clients/setEditedItem", Object.assign({}, {}));
      this.customerDialog = true;
    },
    editItem(item) {
      this.$store.commit("setOverlay", true);
      this.$store
        .dispatch("clients/getCustomerWithDetails", item.id)
        .then(() => {
          this.$store
            .dispatch("users/fetchStrykerUsers")
            .then(() => {
              this.$store.commit("setOverlay", false);
              this.customerDialog = true;
            })
            .catch(() => {
              this.$store.commit("setOverlay", false);
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.user-stryker"),
                color: "error"
              });
            });
        })
        .catch(() => {
          this.$store.commit("setOverlay", false);
          this.$store.dispatch("showSnackbar", {
            message: this.$t("alert.error.customer"),
            color: "error"
          });
        });
    },

    deleteItem(item) {
      if (confirm(this.$t("alert.delete.customer"))) {
        this.$store
          .dispatch("clients/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.customer-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "customers");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.customer-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
