<template>
  <v-row justify="center" class="mt-2">
    <v-pagination
      class="mb-2"
      v-model="currentPage"
      @next="goToStep"
      @prev="goToStep"
      :length="totalPages"
      :total-visible="5"
      @update:modelValue="goToStep"
    ></v-pagination>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import axios from "axios";

export default {
  name: "PaginationDynamic",
  props: ["store"],
  data() {
    return {
      notGoto: true
    };
  },
  computed: {
    ...mapState({
      totalCount: (state) => state.paginations.totalCount,
      totalPages: (state) => state.paginations.totalPages,
      currentFilter: (state) => state.paginations.currentFilter,
      currentOrderFilter: (state) => state.paginations.currentOrderFilter,
      hasNext: (state) => state.paginations.hasNext,
      hasPrevious: (state) => state.paginations.hasPrevious,
      loadingDatatables: (state) => state.loadingTables,
      search: (state) => state.paginations.search
    }),
    currentPage: {
      get() {
        return this.$store.state.paginations.currentPage;
      },
      set(value) {
        this.$store.commit("paginations/setCurrentPage", value);
      }
    }
  },
  methods: {
    goToStep(item) {
      this.$store.commit("setLoadingTables", true);
      var paginationParams = "";
      if (this.currentFilter != "" && this.currentOrderFilter != "") {
        paginationParams =
          "?pageNumber=" +
          item +
          "&orderBy=" +
          this.currentFilter +
          " " +
          this.currentOrderFilter +
          "";
      } else {
        paginationParams = "?pageNumber=" + item + "";
      }
      if (this.search != "") {
        paginationParams += "&search=" + this.search;
      }
      const data = {
        text: paginationParams
      };
      this.$store.dispatch(`${this.store}/fetchAll`, data).then((response) => {
        this.$store.commit("setLoadingTables", false);
        this.$store.commit(
          "paginations/setItems",
          JSON.parse(response.headers.get("X-Pagination"))
        );
      });
    }
  }
};
</script>
