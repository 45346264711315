import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import Contrat from "@/views/ContactView.vue";
import StrykerCommands from "@/views/StrykerCommands.vue";
import StrykerOneCommands from "@/views/StrykerOneCommands.vue";
import ContratView from "@/views/ContratView.vue";
import AdminView from "@/views/AdminView.vue";
import OneStrykerService from "@/views/OneStrykerService.vue";
import CartView from "@/views/CartView.vue";
import PointView from "@/views/PointView.vue";
import SelectCustomerView from "@/views/SelectCustomerView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import AccessibilityView from "@/views/AccessibilityView.vue";
import DisclaimerView from "@/views/DisclaimerView.vue";
import TermsOfUseView from "@/views/TermsOfUseView.vue";
import CreateOrResetPasswordView from "@/views/CreateOrResetPasswordView.vue";

import store from "../store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/contact",
    name: "contact",
    component: Contrat
  },
  {
    path: "/stryker-commands",
    name: "stryker-commands",
    component: StrykerCommands
  },
  {
    path: "/stryker-one-commands",
    name: "stryker-one-commands",
    component: StrykerOneCommands
  },
  {
    path: "/contrat",
    name: "contrat",
    component: ContratView
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView
  },
  {
    path: "/service-one-stryker",
    name: "service-one-stryker",
    component: OneStrykerService
  },
  {
    path: "/points",
    name: "points",
    component: PointView
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView
  },
  {
    path: "/select-customer",
    name: "select-customer",
    component: SelectCustomerView
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: AccessibilityView
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: DisclaimerView
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsOfUseView
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: CreateOrResetPasswordView,
    props: (route) => ({
      userId: route.query.userId,
      token: decodeURIComponent(route.query.token).replace(/ /g, "+")
    })
  },
  {
    /* path: '/about',
    name: 'about', */
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      store.commit("auth/clearAuthData");
      if (router.currentRoute.value.path !== "/login") {
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);

router.beforeEach(async (to, from, next) => {
  // Constants
  const publicPages = [
    "/login",
    "/privacy",
    "/accessibility",
    "/disclaimer",
    "/terms-of-use",
    "/resetpassword"
  ];
  const customerIdPages = [
    "/",
    "/stryker-one-commands",
    "/stryker-commands",
    "/contrat",
    "/service-one-stryker",
    "/points"
  ];

  // 1. Authentication Check
  const token = store.state.auth.token || localStorage.getItem("authToken");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    store.commit("auth/setToken", token);
  }

  const loggedIn = !!token;

  // 2. User Info Check
  try {
    if (loggedIn && !store.state.users.user) {
      await store.dispatch("users/getUserInfosWithCustomers");
    }

    const user = store.state.users.user;
    const isAdmin = user?.userRole === "ADMIN";

    // 3. Customer Check
    let selectedCustomer =
      store.state.users.selectedCustomer ||
      JSON.parse(localStorage.getItem("cust_stryker_one"));

    if (selectedCustomer) {
      store.commit("users/setSelectedCustomer", selectedCustomer);

      // Load necessary data for specific routes
      if (to.path === "/stryker-commands" && !from.name) {
        await Promise.all([
          store.dispatch(
            "strykerCommands/fetchCustommerCommands",
            selectedCustomer.id
          ),
          store.dispatch("statsCustomer/fetChSpecificStatsCustomer", {
            id: selectedCustomer.id,
            name: isAdmin ? "admin" : ""
          })
        ]);
      }

      if (to.path === "/" && from.path !== "/admin") {
        await Promise.all([
          store.dispatch("users/getUserInfosWithCustomers"),
          store.dispatch("statsCustomer/fetChSpecificStatsCustomer", {
            id: selectedCustomer.id,
            name: isAdmin ? "admin" : ""
          }),
          store.dispatch("cart/fetchCartByCustomer", selectedCustomer.id)
        ]);
      }
    }

    // 4. Navigation Guards
    if (loggedIn && to.path === "/login") {
      return next("/");
    }

    if (!loggedIn && !publicPages.includes(to.path)) {
      return next("/login");
    }

    if (loggedIn && customerIdPages.includes(to.path) && !selectedCustomer) {
      if (!isAdmin) {
        return next("/select-customer");
      }
      return next("/admin");
    }

    // 5. Route Validation
    if (!router.hasRoute(to.name) && to.path !== "/") {
      return next("/");
    }

    return next();
  } catch (error) {
    console.error("Router navigation error:", error);
    if (!publicPages.includes(to.path)) {
      store.commit("auth/clearAuthData");
      return next("/login");
    }
    return next();
  }
});

export default router;
