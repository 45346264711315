import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/gimBrands${paginationString.text}`);
    } else {
      return axios.get("/api/gimBrands");
    }
  },
  store(gimBrand) {
    return axios.post("/api/gimBrands", gimBrand);
  },
  delete(id) {
    return axios.delete(`/api/gimBrands/${id}`);
  },
  update(id, gimBrand) {
    return axios.put(`/api/gimBrands/${id}`, gimBrand);
  }
};
